<template>
  <div
    class="relative z-10"
  >
    <T3Renderer
      v-if="props?.header?.length"
      :content="props.header"
    />
  </div>
  <div
    class="relative z-10 grid grid-cols-12 gap-6"
    :class="{
      '-mx-8 lg:mx-0': isSliderHeroLayout,
    }"
    data-type="two-columns-container"
  >
    <div :class="columnClasses?.left">
      <T3Renderer
        v-if="props?.left?.length"
        :content="props.left"
      />
    </div>
    <div
      :data-type="isSliderHeroLayout ? 'column-min' : null"
      :class="columnClasses?.right"
    >
      <T3Renderer
        v-if="props?.right?.length"
        :content="props.right"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { T3CeRawlplugTwoColumnsContainer } from './T3Ce2colsWithHeaderContainer.types.ts'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<T3CeRawlplugTwoColumnsContainer>(), {
  grid: '1/2-1/2',
  left: () => [],
  right: () => [],
})

const isSliderHeroLayout = computed(() => props.grid === '3/4-1/4')

const GRID_1_2 = 'col-span-12 xl:col-span-6'
const GRID_12_8 = 'col-span-12 xl:col-span-8'
const GRID_12_4 = 'col-span-12 xl:col-span-4'
const GRID_12_9 = 'col-span-12 lg:col-span-8 xl:col-span-9'
const GRID_12_3 = 'col-span-12 lg:col-span-4 xl:col-span-3'

const columnClasses = computed(() => {
  switch (props.grid) {
    case '1/2-1/2':
      return {
        left: GRID_1_2,
        right: GRID_1_2,
      }
    case '2/3-1/3': {
      return {
        left: GRID_12_8,
        right: GRID_12_4,
      }
    }
    case '1/3-2/3': {
      return {
        left: GRID_12_4,
        right: GRID_12_8,
      }
    }
    case '1/4-3/4': {
      return {
        left: GRID_12_3,
        right: GRID_12_9,
      }
    }
    case '3/4-1/4': {
      return {
        left: GRID_12_9,
        right: GRID_12_3,
      }
    }
    default:
      return {
        left: GRID_1_2,
        right: GRID_1_2,
      }
  }
})
</script>

<style lang="postcss">
/* Remove margin from first and last element in two columns container */
[data-type='two-columns-container'] {
  & > div:first-child > .t3-ce-frame {
    @apply mt-0;
  }
  & > div:last-child > .t3-ce-frame {
    @apply mt-0;
  }

  /* adapt side column size to match main column in 3/4-1/4 layout */
  & > div[data-type='column-min'] {
    @apply xl:h-0 xl:min-h-full flex flex-col gap-x-6 gap-y-4;
  }
  & > div[data-type='column-min'] > .t3-ce-frame:last-child {
    @apply xl:flex-grow xl:h-0;
  }
}
</style>
